<template>
  <div style="margin-bottom: 10%">
    <pre id="content" style="white-space: pre-wrap;"></pre>
    <v-row>
      <v-col cols="1" sm="1"></v-col>
      <v-col cols="10" sm="10">
        <h2 class="ScheduledEvents">
          Scheduled Events
          <v-divider/>
        </h2>

      <v-sheet height="64">
        <v-toolbar
          flat
        >
          <!-- <v-btn
            outlined
            class="mr-4 button"
            color="grey darken-2"
            @click="setToday"
          >
            Today
          </v-btn> -->

          <v-spacer></v-spacer>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>

          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>

          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <!-- <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                class="button"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
          event-overlap-mode="column"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
      </v-col>
      <v-col cols="1" sm="1"></v-col>
    </v-row>
    <v-btn
        outlined
        class="button"
        to='/Contact'
    >
      Contact Us
    </v-btn>
  </div>
</template>
<script src="https://apis.google.com/js/api.js"></script>
<script>
  export default {
    data: () => ({
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'grey darken-1'],
      names: ['Wedding', 'Event', 'Party'],
      authorized: false,
      items: undefined
    }),
    async mounted () {
      this.$refs.calendar.checkChange();
      const calendar = this.$refs.calendar;
      // await this.viewAugust();
      await calendar.prev();
      await calendar.next();
    },
    created() {
      this.api = gapi;
      this.handleClientLoad();
    },
    methods: {
      viewDay ({ date }) {
        // this.focus = date
        // this.type = 'day'
      },
      async viewAugust() {
        const date = new Date();
        const month = date.getMonth();
        console.log("Current Month: ", month);
        const monthsBehind = 7 - month;
        console.log("Months behind: ", monthsBehind);
        const calendar = this.$refs.calendar;
        if (monthsBehind > 0){
          await calendar.move(monthsBehind);
          await calendar.prev();
          await calendar.next();
        }
        else {
          await calendar.move(0);
        }
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => {
            this.selectedOpen = true
          }, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      handleClientLoad() {
          this.api.load('client:auth2', this.initClient);
      },
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      initClient() {
        // console.log("Init Client");
        var CLIENT_ID = "639648306089-4l57celh23f83bf3rudej2aiiqeimeqa.apps.googleusercontent.com";
        // var API_KEY = "Guc5OJd-6wOy440tDjt3o8nX";
        var API_KEY = "AIzaSyAa8oLhx0mxOFkxTblSkC8cypdjbeQ1U1c";

        var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];

        // Authorization scopes required by the API; multiple scopes can be
        // included, separated by spaces.
        var SCOPES = "https://www.googleapis.com/auth/calendar.readonly";
        let vm = this;
        vm.api.client.init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES
        }).then(_ => {
          vm.api.auth2.getAuthInstance().isSignedIn.listen(vm.authorized);
        });
      },
      async updateRange ({ start, end }) {
        //const events = []
        const zeroPad = (num, places) => String(num).padStart(places, '0');
        console.log("updateRange - Start: ", start);
        console.log("updateRange - End: ", end);

        var CLIENT_ID = "639648306089-4l57celh23f83bf3rudej2aiiqeimeqa.apps.googleusercontent.com";
        // var API_KEY = "Guc5OJd-6wOy440tDjt3o8nX";
        var API_KEY = "AIzaSyAa8oLhx0mxOFkxTblSkC8cypdjbeQ1U1c";

        var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];

        // Authorization scopes required by the API; multiple scopes can be
        // included, separated by spaces.
        var SCOPES = "https://www.googleapis.com/auth/calendar.readonly";

        // TODO: Figure out a better way to solve the race condition with the api
        while(this.api.client === undefined){
          await this.sleep(50);
        }
        while(this.api.client.calendar === undefined){
          await this.sleep(50);
        }

        let vm = this;
        console.log("GAPI: ", this.api);

        var endYear = parseInt(end.date.substring(0,4));
        var endMonth = parseInt(end.date.substring(5,7));
        var endDay = parseInt(end.date.substring(8,10));

        console.log("End Year: ", endYear);
        console.log("End Month:", endMonth);
        console.log("End Day:", endDay);

        if(endMonth == 12){
          endYear = endYear + 1;
          endMonth = 1;
          endDay = 1;
        }
        else{
          endMonth = endMonth + 1;
          endDay = 1;
        }

        let startDate = start.date + "T00:00:00.0z";
        let endDate = endYear.toString() + '-' + zeroPad(endMonth, 2) + '-' + zeroPad(endDay, 2) + "T00:00:00.0z";

        console.log("End Date: ", endDate);

        const response = await this.api.client.calendar.events.list({
          'calendarId': 'h66gio84hh2ijlf7jvvcdji2og@group.calendar.google.com',
          'timeMin': startDate,
          'timeMax': endDate,
          'showDeleted': false,
          'singleEvents': true,
          'maxResults': 100,
          'orderBy': 'startTime'
        });
        console.log("List Response: ", response);
        console.log("Total Events: ", response.result.items.length);
        var events = response.result.items;
        var calendarEvents = [];

        for (var i = 0; i < events.length; i++) {
          var event = events[i];
          // console.log("Event: ", event);
          var startTime = undefined;
          var endTime = undefined;
          var eventColor = undefined;
          if (event.start.dateTime !== undefined){
            startTime = event.start.dateTime.substring(0,10);
            endTime = event.end.dateTime.substring(0,10);
          }
          // When it is just a date, it is usually an all day event
          else {
            startTime = event.start.date.substring(0,10);
            endTime = startTime;
          }
          if (event.summary === "Building Barn"){
            eventColor = "red";
          }
          else{
            eventColor = "blue";
          }
          var eventObject = {
            name: event.summary,
            start: startTime,
            end: endTime,
            color: eventColor,
            timed: false
          }
          calendarEvents.push(eventObject);
        }
        console.log("Calendar Events: ", calendarEvents);
        this.events = calendarEvents;
      },
    },
  }
</script>

<style scoped>
.ScheduledEvents {
  padding: 5%;
}
.button {
    align-self: center;
    font-style: italic;
    border-radius: 0;
}
</style>
